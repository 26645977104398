import React, { useState, lazy, Suspense } from "react";
import { FiCheckCircle, FiXCircle } from "react-icons/fi";
const RenderContent = lazy(() => import("../../RenderContent"));

const TextQuestion = ({
  question,
  showAnswer,
  handleAnswerSelection,
  selectedAnswer,
  correctAnswer,
  currentQuestion,
}) => {
  const [userAnswer, setUserAnswer] = useState(selectedAnswer || "");

  const handleInputChange = (e) => {
    setUserAnswer(e.target.value);
  };

  const sanitizeInput = (input) => {
    if (input.includes("=")) {
      return input.replace(/\s+/g, "").toLowerCase();
    }
    return input.toLowerCase();
  };

  const isPartialMatch = (inputAnswer, baseAnswer) => {
    const sanitizedInput = sanitizeInput(inputAnswer);
    const sanitizedBase = sanitizeInput(baseAnswer);
    const inputWords = sanitizedInput.split(/\s+/);
    const baseWords = sanitizedBase.split(/\s+/);

    return inputWords.some((word) => baseWords.includes(word));
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    handleAnswerSelection(userAnswer);
  };

  const answerIsCorrect = isPartialMatch(userAnswer, correctAnswer);

  return (
    <div>
      <Suspense fallback={<div>Loading...</div>}>
        <h2 className="text-lg font-bold mb-2">
          <RenderContent content={question.text} />
        </h2>
      </Suspense>

      <form onSubmit={handleSubmit}>
        <div className="relative">
          <input
            type="text"
            value={userAnswer}
            onChange={handleInputChange}
            disabled={showAnswer}
            className={`border-2 rounded-md p-2 focus:outline-none focus:ring-2 focus:ring-blue-500 ${
              showAnswer &&
              (answerIsCorrect ? "border-green-500" : "border-red-500")
            }`}
          />
          {showAnswer && (
            <div
              className={`absolute top-1 right-1 ${
                answerIsCorrect ? "text-green-500" : "text-red-500"
              }`}
            >
              {answerIsCorrect ? (
                <FiCheckCircle className="text-xl" />
              ) : (
                <FiXCircle className="text-xl" />
              )}
            </div>
          )}
        </div>
        {!showAnswer && (
          <button
            type="submit"
            className="mt-4 bg-blue-500 text-white px-4 py-2 rounded-md hover:bg-blue-600 focus:outline-none focus:ring-2 focus:ring-blue-500"
          >
            Submit
          </button>
        )}
      </form>
    </div>
  );
};

export default TextQuestion;
