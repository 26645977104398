import React from "react";

const QuizSettings = ({ quizTime, handleQuizTimeChange }) => {
  return (
    <div>
      <div className="mb-4">
        <label htmlFor="quizTime" className="font-bold">
          Quiz Time (seconds):
        </label>
        <input
          type="number"
          id="quizTime"
          className="ml-2 border rounded-md p-1"
          value={quizTime}
          onChange={handleQuizTimeChange}
        />
      </div>

      {/* Add other quiz settings components here */}
    </div>
  );
};

export default QuizSettings;
