import React, { useState, useEffect, lazy, Suspense } from "react";
const RenderContent = lazy(() => import("../../RenderContent"));

const MultipleChoiceQuestion = ({
  question,
  showAnswer,
  selectedAnswer,
  selectedAnswers,
  correctAnswer,
  handleAnswerSelection,
  currentQuestion, // add this line
  solution,
}) => {
  // console.log("inside mcq question", question, selectedAnswers);
  // console.log("showAnswer", showAnswer);

  const handleOptionSelection = (option) => {
    // console.log("selectedAnswers:", selectedAnswers);
    // console.log("Option:", option);
    let selectedAnswersForCurrentQuestion =
      (selectedAnswers && selectedAnswers[currentQuestion]) || [];

    if (question.multiple_answers) {
      if (selectedAnswersForCurrentQuestion.includes(option)) {
        // Deselect the option if it's already selected
        selectedAnswersForCurrentQuestion =
          selectedAnswersForCurrentQuestion.filter(
            (answer) => answer !== option
          );
      } else {
        // Select the option
        selectedAnswersForCurrentQuestion = [
          ...(selectedAnswersForCurrentQuestion || []),
          option,
        ];
      }
    } else {
      // Single answer question, replace the answer
      // console.log("option", option);
      selectedAnswersForCurrentQuestion = [option];
    }

    // console.log(
    //   "selectedAnswersForCurrentQuestion:",
    //   selectedAnswersForCurrentQuestion
    // );

    // Pass selectedAnswersForCurrentQuestion directly to handleAnswerSelection
    handleAnswerSelection(selectedAnswersForCurrentQuestion);
  };

  // Helper function to get the label based on the index
  const getOptionLabel = (index) => {
    // Assuming a maximum of 26 options (A to Z)
    return String.fromCharCode(65 + index);
  };

  return (
    <div>
      <Suspense fallback={<div>Loading...</div>}>
        <h2 className="text-lg font-bold mb-2">
          <RenderContent content={question.text} />
        </h2>
      </Suspense>

      {question.options.map((option, optionIndex) => {
        // console.log("selectedAnswers", selectedAnswers);
        const isSelected =
          selectedAnswers && selectedAnswers[currentQuestion]
            ? selectedAnswers[currentQuestion].includes(option.value)
            : false;

        // console.log("selectedAnswers", selectedAnswers);
        // console.log(`Is option ${option.value} selected?`, isSelected);

        const isCorrect = correctAnswer.includes(option.value);

        let optionClassNames = "flex items-center mb-2";
        if (showAnswer) {
          if (isSelected && isCorrect) {
            optionClassNames += " bg-green-500";
          } else if (isSelected && !isCorrect) {
            optionClassNames += " bg-red-500";
          } else if (!isSelected && isCorrect) {
            optionClassNames += " bg-green-500";
          }
        }

        return (
          <div
            key={optionIndex}
            className={`${optionClassNames} p-4 border rounded-md shadow-sm`}
          >
            <div className="flex items-center">
              {" "}
              {/* Adjust here */}
              {/* Display the option label */}
              <span className="mr-2 font-bold">
                {getOptionLabel(optionIndex)}
              </span>
              <div className="relative flex items-center mr-2">
                {" "}
                {/* Additional wrapper div */}
                <input
                  type={question.multiple_answers ? "checkbox" : "radio"}
                  id={`option_${optionIndex}`}
                  name="question"
                  checked={isSelected}
                  disabled={showAnswer}
                  onChange={() => handleOptionSelection(option.value)}
                  className="w-6 h-6" /* removed mr-2 from here */
                />
              </div>
              <label
                htmlFor={`option_${optionIndex}`}
                className={`ml-2 ${isSelected ? "" : ""} cursor-pointer`}
              >
                <RenderContent content={option.text} />
              </label>
            </div>
          </div>
        );
      })}
    </div>
  );
};

export default MultipleChoiceQuestion;
