import React, { useState } from "react";
import { Link } from "react-router-dom";

import {
  FaFacebookF,
  FaTwitter,
  FaYoutube,
  FaPinterestP,
  FaEnvelope,
  FaApple,
  FaChrome,
  FaBook,
  FaInfinity,
  FaInstagram, // Import the Instagram icon
} from "react-icons/fa";
import { BsLightningFill } from "react-icons/bs";
import { MdArrowForwardIos, MdArrowBackIos } from "react-icons/md";
import { featuredGuide } from "../constants/featured";
import { FaGraduationCap, FaLightbulb } from "react-icons/fa";
import { useNavigate } from "react-router-dom";
import { RainbowButton } from "./Rainbow"; // Adjust the import path as necessary
import { MdOutlineAutoAwesome } from "react-icons/md";
const iconStyle = { color: "#FFEB3B", marginRight: "8px" };
const redInfinityStyle = {
  color: "#FFEB3B",
  marginLeft: "8px",
  marginRight: "8px",
};

function Footer() {
  const currentYear = new Date().getFullYear();
  const navigate = useNavigate();
  const guidesPerPage = 3; // Number of guides to display per page
  const [currentIndex, setCurrentIndex] = useState(0);
  const [visibleGuides, setVisibleGuides] = useState(featuredGuide.slice(0, 3)); // Initially showing the first 3 guides

  const handleGuideSelect = (guide) => {
    navigate(
      `/video?id=${guide.recordingSessionId}&source=server&uid=${guide.uid}`
    );
  };

  // Function to scroll to the top of the page
  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth", // For a smooth scroll
    });
  };

  const handleNext = () => {
    const newIndex =
      currentIndex + guidesPerPage < featuredGuide.length
        ? currentIndex + guidesPerPage
        : currentIndex;
    setCurrentIndex(newIndex);
    setVisibleGuides(featuredGuide.slice(newIndex, newIndex + guidesPerPage));
  };

  const handlePrev = () => {
    const newIndex =
      currentIndex - guidesPerPage >= 0 ? currentIndex - guidesPerPage : 0;
    setCurrentIndex(newIndex);
    setVisibleGuides(featuredGuide.slice(newIndex, newIndex + guidesPerPage));
  };

  return (
    <footer className="bg-gray-100 py-8">
      {/* FeaturedGuide Section */}
      <div className="container mx-auto px-4 text-center mb-6"></div>
      <div className="flex flex-col items-center mb-8 mt-8 px-4 sm:px-6 lg:px-8">
        <RainbowButton
          onClick={() => {
            /* Navigation logic if necessary */
          }}
        >
          <Link to="/" className="flex items-center">
            <MdOutlineAutoAwesome className="mr-2  text-2xl" /> {/* AI Icon */}
            <span className="text-5xl font-semibold ">Kimavi</span>
          </Link>
        </RainbowButton>
        <span className="flex flex-col sm:flex-row items-center justify-center mt-8 mb-8 space-x-0 sm:space-x-2 space-y-2 sm:space-y-0 text-center">
          <span className="text-xl sm:text-2xl md:text-3xl font-semibold text-gray-500 dark:text-gray-200">
            Enabling
          </span>
          <BsLightningFill className="text-3xl sm:text-4xl text-gray-500" />
          <span className="text-xl sm:text-2xl md:text-3xl font-semibold text-gray-500 dark:text-gray-200">
            FAST Digital Adoption (with Generative AI)
          </span>
        </span>
      </div>

      <div className="container mx-auto px-4 text-center mb-6">
        <h2 className="text-xl font-bold text-gray-800 mb-4">
          Featured Guides
        </h2>
        <div className="flex flex-col md:flex-row justify-between items-center">
          <button
            onClick={handlePrev}
            aria-label="Previous guides"
            disabled={currentIndex === 0}
            className={`rounded-full m-2 p-2 bg-gray-200 hover:bg-gray-300 focus:outline-none focus:ring-2 focus:ring-gray-400 focus:ring-opacity-50 ${
              currentIndex === 0 ? "opacity-50 cursor-not-allowed" : ""
            }`}
          >
            <MdArrowBackIos className="text-gray-800" />
          </button>
          <div className="flex flex-col md:flex-row space-x-0 md:space-x-4 overflow-hidden justify-center mb-4 md:mb-0">
            {visibleGuides.map((guide, index) => (
              <div
                key={index}
                className="max-w-md bg-white rounded-xl shadow-md overflow-hidden md:max-w-2xl mb-4 md:mb-0"
                onClick={() => handleGuideSelect(guide)}
              >
                <div className="p-8">
                  <a
                    href="#"
                    onClick={(e) => e.preventDefault()}
                    className="block mt-1 text-lg leading-tight font-medium text-black hover:underline"
                  >
                    {guide.guideTitle}
                  </a>
                  <p className="mt-2 text-gray-500">#{guide.tags.join(" #")}</p>
                </div>
              </div>
            ))}
          </div>
          <button
            onClick={handleNext}
            aria-label="Next guides"
            disabled={currentIndex + guidesPerPage >= featuredGuide.length}
            className={`rounded-full m-2 p-2 bg-gray-200 hover:bg-gray-300 focus:outline-none focus:ring-2 focus:ring-gray-400 focus:ring-opacity-50 ${
              currentIndex + guidesPerPage >= featuredGuide.length
                ? "opacity-50 cursor-not-allowed"
                : ""
            }`}
          >
            <MdArrowForwardIos className="text-gray-800" />
          </button>
        </div>
      </div>

      <div className="container mx-auto px-4 text-center">
        <hr className="my-6 border-gray-700" />

        <div className="mb-3 flex space-x-4 justify-center">
          <a href="mailto:admin@kimavi.com" size="6">
            <FaEnvelope />
          </a>

          <a
            href="https://www.youtube.com/kimavi"
            target="_blank"
            rel="noopener noreferrer"
            size="6"
          >
            <FaYoutube />
          </a>

          <a
            href="https://chromewebstore.google.com/detail/kimavi-create-how-to-vide/kcleenakjlelbmchbkfpgoldgglhdpne?hl=en&authuser=0&pli=1"
            target="_blank"
            rel="noopener noreferrer"
            aria-label="Kimavi on Chrome Web Store"
          >
            <FaChrome />
          </a>
          {/* Icons remain unchanged as they are typically already suitable for responsive design */}
        </div>

        <div className="flex flex-wrap items-center justify-center mb-4">
          <div className="w-full md:w-6/12 lg:w-4/12 px-4">
            <div className="text-xs sm:text-sm text-gray-400 font-semibold py-1">
              &copy; {currentYear} Kimavi. All rights reserved.
            </div>
            <div className="text-xs sm:text-sm text-gray-400 font-semibold py-1 mb-5 mt-5">
              A Generative AI Platform for Digital Adoption
            </div>
          </div>
        </div>

        <div className="flex flex-col items-center mb-4">
          <div className="border p-2 rounded bg-gray-300">
            <Link to="/privacy-security-cookie">
              <span className="text-xs font-semibold text-gray-500 pb-2 pt-3">
                Terms of Use: Privacy, Security and Cookie Policy
              </span>
            </Link>
          </div>
        </div>

        <div className="flex flex-wrap items-center justify-center mb-4">
          <div className="w-full md:w-6/12 lg:w-4/12 px-4">
            <div className="text-xs sm:text-sm text-gray-400 font-semibold py-1">
              In Public Beta - Please report issues to{" "}
              <a href="mailto:admin@kimavi.com" className="text-gray-500">
                admin@kimavi.com
              </a>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
}

export default Footer;
