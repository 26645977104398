import React, { useState, useEffect, useRef } from "react";
import PreviewGuide from "../PreviewGuide";
import getJSONfromPublic from "../getJSONfromPublic"; // Adjust this path to where your getJSONfromPublic function is located
import { RainbowText, RainbowButton, RainbowButton1 } from "./Rainbow";
import { useNavigate } from "react-router-dom";
import { MdOutlineAutoAwesome } from "react-icons/md";
import useOnScreen from "../useOnScreen"; // Adjust the import path to where your useOnScreen hook is located

// Updated words and descriptions to match the new marketing message
const actionWords = ["Revolutionize", "Elevate"];
const actionDescriptions = [
  "Effortlessly Create How-To Video Masterpiece with Generative AI",
  "Collaborate and Share Videos with Ease in Landsape and Portrait Mode",
  "10X Your Digital Adoption Video Productivity",
];

const actionDescriptions1 = [
  "Revolutionize Digital Adoption GIF Creation with Generative AI",
  "Elevate your GIF creation process with the magic of Generative AI.",
  "10X Your Digital Adoption GIF Productivity",
];

const actionDescriptions2 = [
  "Revolutionize Digital Adoption Google/Microsoft Slide Creation with the Generative AI",
  "Elevate your Google Slide and Powerpoint Digital Adoption presentations with the power of Generative AI.",
  "Accelerate your Digital Adoption Slide creation process, making it 10X faster and more innovative.",
];
// Utilize Tailwind CSS for styling and animation
const tailwindBlink = "animate-ping";

function FeaturedGuide() {
  // Create refs for your lazy-loaded components
  const FBRef = useRef();
  const llmRef = useRef();
  const guideRef = useRef();
  const howtoRef = useRef();
  const chatGPTRef = useRef();
  const portraitRef = useRef();
  const docRef = useRef();

  // Use the custom hook to check if the elements are in the viewport
  const isFBVisible = useOnScreen(FBRef);
  const isLlmVisible = useOnScreen(llmRef);
  const isGuideVisible = useOnScreen(guideRef);
  const isHowtoVisible = useOnScreen(howtoRef);
  const isChatGPTVisible = useOnScreen(chatGPTRef);
  const isPortraitVisible = useOnScreen(portraitRef);
  const isDocVisible = useOnScreen(docRef);

  const [persistVisibility, setPersistVisibility] = useState({
    FB: false,
    llm: false,
    guide: false,
    howto: false,
    chatGPT: false,
    portrait: false,
    doc: false,
  });

  // Updated visibility logic with persisting behavior
  useEffect(() => {
    const updateVisibility = (isVisible, key) => {
      if (isVisible && !persistVisibility[key]) {
        setPersistVisibility((prev) => ({ ...prev, [key]: true }));
      }
    };

    updateVisibility(isFBVisible, "FB");
    updateVisibility(isLlmVisible, "llm");
    updateVisibility(isGuideVisible, "guide");
    updateVisibility(isHowtoVisible, "howto");
    updateVisibility(isChatGPTVisible, "chatGPT");
    updateVisibility(isPortraitVisible, "portrait");
    updateVisibility(isDocVisible, "doc");
  }, [
    isFBVisible,
    isLlmVisible,
    isGuideVisible,
    isHowtoVisible,
    isChatGPTVisible,
    isPortraitVisible,
    isDocVisible,
  ]);

  const navigate = useNavigate();
  const [actionIndex, setActionIndex] = useState(0);
  const [guideData, setGuideData] = useState(null); // State to hold fetched guide data
  const [howtoData, setHowToData] = useState(null); // State to hold fetched guide data
  const [chatGPT, setChatGPT] = useState(null); // State to hold fetched guide data
  const [portraitData, setPortraitData] = useState(null); // State to hold fetched guide data
  const [docData, setDocData] = useState(null); // State to hold fetched guide data
  const [llmData, setLlmData] = useState(null); // State to hold fetched guide data
  const [FBData, setFBData] = useState(null); // State to hold fetched guide data
  const [contentTypeIndex, setContentTypeIndex] = useState(0); // State for cycling content types
  const contentTypes = ["Videos", "GIF", "Doc", "Slide"];
  const currentContentType = contentTypes[contentTypeIndex]; // Current content type (Videos, GIF, Doc, Slide)

  useEffect(() => {
    const contentTypeInterval = setInterval(() => {
      setContentTypeIndex((prevIndex) => (prevIndex + 1) % contentTypes.length);
    }, 2000); // Change the content type every 2 seconds
    return () => clearInterval(contentTypeInterval);
  }, []);

  // git wVdZ4zMt1PYD6mmNflk6ZApiZEe2
  // wag YcHmlVjlgaMTJldkZ3I2cyuaEj82

  useEffect(() => {
    const fetchGuideData = async () => {
      // Define your UID and session IDs for each type of guide
      const guideSessionId = "recording_1710801416482_510852"; // Replace with your actual session ID
      const howtoSessionId = "recording_1709971042833_7218fbbdad965"; // Replace with your actual session ID
      const chatGPTSessionId = "recording_1710269452137_7b3f2a88975b4"; // Replace with your actual session ID
      const portraitVideoSessionId = "recording_1711386443960_556407"; // Replace with your actual session ID
      const docId = "recording_1711867514055_2e6af5342e498"; // Replace with your actual session ID
      const LLMId = "recording_1715028107857_69e3286ae4754"; // Replace with your actual session ID
      const FBId = "recording_1714859084370_867432"; // Replace with your actual session ID

      try {
        const FBData = await getJSONfromPublic(
          "wVdZ4zMt1PYD6mmNflk6ZApiZEe2",
          FBId
        );

        const LLMData = await getJSONfromPublic(
          "wVdZ4zMt1PYD6mmNflk6ZApiZEe2",
          LLMId
        );
        const guideData = await getJSONfromPublic(
          "wVdZ4zMt1PYD6mmNflk6ZApiZEe2",
          guideSessionId
        );
        const howtoData = await getJSONfromPublic(
          "wVdZ4zMt1PYD6mmNflk6ZApiZEe2",
          howtoSessionId
        );
        const chatGPTData = await getJSONfromPublic(
          "wVdZ4zMt1PYD6mmNflk6ZApiZEe2",
          chatGPTSessionId
        );

        const portraitData = await getJSONfromPublic(
          "YcHmlVjlgaMTJldkZ3I2cyuaEj82",
          portraitVideoSessionId
        );

        const docData = await getJSONfromPublic(
          "YcHmlVjlgaMTJldkZ3I2cyuaEj82",
          docId
        );
        if (FBData)
          setFBData({
            ...FBData,
            guideTitle: "Featured AI Guide: " + FBData.guideTitle,
          });
        if (LLMData)
          setLlmData({
            ...LLMData,
            guideTitle: "Featured Generative Guide: " + LLMData.guideTitle,
          });

        if (guideData)
          setGuideData({
            ...guideData,
            guideTitle: "Featured Guide: " + guideData.guideTitle,
          });
        if (howtoData)
          setHowToData({
            ...howtoData,
            guideTitle: "Featured Video: " + howtoData.guideTitle,
          });
        if (chatGPTData)
          setChatGPT({
            ...chatGPTData,
            guideTitle: "Featured GIF: " + chatGPTData.guideTitle,
          });
        if (portraitData)
          setPortraitData({
            ...portraitData,
            guideTitle: "Featured Portrait Video: " + portraitData.guideTitle,
          });
        if (docData)
          setDocData({
            ...docData,
            guideTitle: "Featured Guide:  " + docData.guideTitle,
          });
        // console.log(portraitData);
      } catch (error) {
        console.error("There was a problem fetching guide data:", error);
      }
    };

    fetchGuideData();

    const wordChangeInterval = setInterval(() => {
      setActionIndex((prevIndex) => (prevIndex + 1) % actionWords.length);
    }, 4000); // Change the word every 4 seconds
    return () => clearInterval(wordChangeInterval);
  }, []);

  const currentDescription1 = actionDescriptions1[actionIndex];
  const currentDescription2 = actionDescriptions2[actionIndex];

  const handleButtonClick = () => {
    window.open(
      "https://chromewebstore.google.com/detail/kimavi-create-how-to-vide/kcleenakjlelbmchbkfpgoldgglhdpne?hl=en&authuser=0&pli=1",
      "_blank"
    );
  };

  return (
    <>
      <div className="flex justify-center mt-8 mb-4">
        <RainbowButton1 onClick={handleButtonClick}>
          <MdOutlineAutoAwesome className="mr-2" />
          Install AI Extension - Create 10X Faster - Digital Adoption Videos
        </RainbowButton1>
      </div>

      <div ref={FBRef} className="m-4 p-4 bg-gray-400 rounded-lg shadow-lg">
        {(isFBVisible || persistVisibility.FB) && FBData && (
          <PreviewGuide guideData={FBData} defaultTab={"gif"} />
        )}
      </div>
      <div ref={llmRef} className="m-4 p-4 bg-gray-400 rounded-lg shadow-lg">
        {(isLlmVisible || persistVisibility.llm) && llmData && (
          <PreviewGuide guideData={llmData} defaultTab={"gif"} />
        )}
      </div>

      <div ref={guideRef} className="m-4 p-4 bg-gray-400 rounded-lg shadow-lg">
        {(isGuideVisible || persistVisibility.guide) && guideData && (
          <PreviewGuide guideData={guideData} defaultTab={"gif"} />
        )}
      </div>

      <header
        className="bg-fixed relative h-96"
        style={{
          backgroundSize: "cover",
        }}
        role="banner"
      >
        <div className="absolute m-4 p-8 inset-0 bg-gray-800  rounded-lg "></div>
        <div className="container mx-auto px-6 md:px-12 text-center py-8 md:py-32 relative">
          <h1 className="text-5xl font-bold mb-8 leading-tight">
            <span className="text-[#00FFFF]">
              Create Digital Adoption{" "}
              <span className="text-[#ffffff]">{currentContentType} </span>with{" "}
              <RainbowButton1 onClick={() => navigate("/create")}>
                <MdOutlineAutoAwesome className="mr-2" />
                Generative AI
              </RainbowButton1>
            </span>
          </h1>
          <p className="text-white text-xl max-w-4xl mx-auto">
            {currentDescription1}
          </p>
        </div>
      </header>
      <section className="bg-gray-50 text-gray-800 py-12 px-6 md:px-12">
        <div className="container mx-auto text-center">
          <h2 className="text-3xl font-bold mb-8 text-gray-400">
            Create{" "}
            <span className="bg-gray-800 text-[#00FFFF] m-1 p-1 rounded-lg">
              {" "}
              <button onClick={() => navigate("/create")}>How-To GIF</button>
            </span>{" "}
            with Generative AI
          </h2>

          <div className="container mx-auto px-6 md:px-12 py-12">
            <div className="grid md:grid-cols-3 gap-4">
              {/* Card 1 */}
              <div className="bg-gray-800 rounded-lg shadow-xl p-6">
                <h3 className="text-lg font-bold mb-2 text-[#00FFFF]">
                  Unlock GIF possibilities
                </h3>
                <p className="text-md mb-4  text-white">
                  Dive into the endless possibilities of content creation,
                  leveraging the magic of Generative AI for unparalleled GIF
                  creativity and efficiency.
                </p>
              </div>

              <div className="bg-gray-800 rounded-lg shadow-xl p-6">
                <h3 className="text-lg font-bold mb-2 text-[#00FFFF]">
                  Enhance GIF sharing
                </h3>
                <p className="text-md mb-4  text-white">
                  Share in landscape and portrait modes and collaborate
                  effortlessly, enhancing teamwork and customer engagement.
                </p>
              </div>

              <div className="bg-gray-800 rounded-lg shadow-xl p-6">
                <h3 className="text-lg font-bold mb-2 text-[#00FFFF]">
                  Boost Digital Productivity
                </h3>
                <p className="text-md mb-4  text-white">
                  Experience a revolutionary leap in productivity, achieving up
                  to 10X faster GIF creation with the latest in Generative AI
                  technology.
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>

      <div className="flex justify-center mt-8 mb-4">
        <RainbowButton1 onClick={handleButtonClick}>
          <MdOutlineAutoAwesome className="mr-2" />
          Install AI Extension - Create 10X Faster - Digital Adoption GIFs
        </RainbowButton1>
      </div>

      <div
        ref={chatGPTRef}
        className="m-4 p-4 bg-gray-400 rounded-lg shadow-lg"
      >
        {(isChatGPTVisible || persistVisibility.chatGPT) && chatGPT && (
          <PreviewGuide guideData={chatGPT} defaultTab={"gif"} />
        )}
      </div>

      <header
        className="bg-fixed relative h-96"
        style={{
          backgroundSize: "cover",
        }}
        role="banner"
      >
        <div className="absolute m-4 p-8 inset-0 bg-gray-800  rounded-lg "></div>
        <div className="container mx-auto px-6 md:px-12 text-center py-8 md:py-32 relative">
          <h1 className="text-5xl font-bold mb-8 leading-tight">
            <span className="text-[#00FFFF]">
              Create Digital Adoption{" "}
              <span className="bg-gray-800 text-[#FFFFFF] m-1 p-1">
                {currentContentType}{" "}
              </span>
              with{" "}
              <RainbowButton1 onClick={() => navigate("/create")}>
                <MdOutlineAutoAwesome className="mr-2" />
                Generative AI
              </RainbowButton1>
            </span>
          </h1>
          <p className="text-white text-xl max-w-4xl mx-auto">
            {currentDescription2}
          </p>
        </div>
      </header>
      <section className="bg-gray-50 text-gray-800 py-12 px-6 md:px-12">
        <div className="container mx-auto text-center">
          <h2 className="text-3xl font-bold mb-8 text-gray-400">
            Create
            <span className=" text-[#00FFFF] m-1 p-1 rounded-lg">
              <RainbowButton1 onClick={() => navigate("/create")}>
                How-To Slide & Doc
              </RainbowButton1>
            </span>
            with Generative AI
          </h2>

          <div className="container mx-auto px-6 md:px-12 py-12">
            <div className="grid md:grid-cols-3 gap-4">
              {/* Card 1 */}
              <div className="bg-gray-800 rounded-lg shadow-xl p-6">
                <h3 className="text-lg font-bold mb-2 text-[#00FFFF]">
                  Magically Create Docs
                </h3>
                <p className="text-md mb-4 text-white">
                  Transform your presentation workflow with AI-powered tools,
                  streamlining the creation of Google Slides for efficiency and
                  speed.
                </p>
              </div>

              <div className="bg-gray-800 rounded-lg shadow-xl p-6">
                <h3 className="text-lg font-bold mb-2 text-[#00FFFF]">
                  Enhance Visual Appeal
                </h3>
                <p className="text-md mb-4 text-white">
                  Elevate the design of your Google Slides with AI-generated
                  visuals, making your presentations more engaging and visually
                  appealing.
                </p>
              </div>
              <div className="bg-gray-800 rounded-lg shadow-xl p-6">
                <h3 className="text-lg font-bold mb-2 text-[#00FFFF]">
                  Innovate with AI
                </h3>
                <p className="text-md mb-4 text-white">
                  Leverage the latest in Generative AI technology to bring
                  creativity and innovation to your Google Slide presentations,
                  setting new standards in your field.
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
      <div className="flex justify-center mt-8 mb-4">
        <RainbowButton1 onClick={handleButtonClick}>
          <MdOutlineAutoAwesome className="mr-2 align-center" />
          Install AI Extension - Create 10X Faster - Digital Adoption Docs
        </RainbowButton1>
      </div>

      <div ref={howtoRef} className="m-4 p-4 bg-gray-400 rounded-lg shadow-lg">
        {(isHowtoVisible || persistVisibility.howto) && howtoData && (
          <PreviewGuide guideData={howtoData} defaultTab={"video"} />
        )}
      </div>

      <div
        ref={portraitRef}
        className="m-4 p-4 bg-gray-400 rounded-lg shadow-lg"
      >
        {(isPortraitVisible || persistVisibility.portrait) && portraitData && (
          <PreviewGuide guideData={portraitData} defaultTab={"portraitVideo"} />
        )}
      </div>
      <div ref={docRef} className="m-4 p-4 bg-gray-400 rounded-lg shadow-lg">
        {(isDocVisible || persistVisibility.doc) && docData && (
          <PreviewGuide guideData={docData} defaultTab={"gif"} />
        )}
      </div>
    </>
  );
}

export default FeaturedGuide;
