import React, { useState } from "react";

const DragDropQuestion = ({ question, showAnswer, handleAnswerSelection }) => {
  const [selectedOptions, setSelectedOptions] = useState({});

  const handleDragStart = (e, option) => {
    e.dataTransfer.setData("text/plain", option);
  };

  const handleDragOver = (e) => {
    e.preventDefault();
  };

  const handleDrop = (e, column) => {
    const option = e.dataTransfer.getData("text/plain");
    setSelectedOptions((prevSelectedOptions) => ({
      ...prevSelectedOptions,
      [option]: column,
    }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    handleAnswerSelection(selectedOptions);
    setSelectedOptions({});
  };

  return (
    <div>
      <h2
        className="text-lg font-bold mb-2"
        dangerouslySetInnerHTML={{ __html: question.text }}
      ></h2>

      <form onSubmit={handleSubmit}>
        <div className="flex">
          <div>
            <h3 className="font-bold">Left Column</h3>
            {question.columns.left.map((option, index) => (
              <div
                key={index}
                draggable={!showAnswer}
                onDragStart={(e) => handleDragStart(e, option)}
                className={`cursor-move mb-2 ${
                  showAnswer && selectedOptions[option] === "left"
                    ? "bg-green-200"
                    : ""
                }`}
                dangerouslySetInnerHTML={{ __html: option }}
              ></div>
            ))}
          </div>

          <div className="ml-4">
            <h3 className="font-bold">Right Column</h3>
            {question.columns.right.map((option, index) => (
              <div
                key={index}
                onDragOver={handleDragOver}
                onDrop={(e) => handleDrop(e, "right")}
                className={`cursor-pointer mb-2 ${
                  showAnswer && selectedOptions[option] === "right"
                    ? "bg-green-200"
                    : ""
                }`}
                dangerouslySetInnerHTML={{ __html: option }}
              ></div>
            ))}
          </div>
        </div>

        {!showAnswer && (
          <button
            type="submit"
            className="mt-4 bg-blue-500 text-white px-4 py-2 rounded-md"
          >
            Submit
          </button>
        )}
      </form>
    </div>
  );
};

export default DragDropQuestion;
