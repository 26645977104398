import React, { useState, useContext, useEffect, useRef } from "react";
import { UserContext } from "../UserContext"; // import UserContext
import { AuthContext } from "../AuthProvider";
import { Link } from "react-router-dom";
import { FaCaretDown, FaUser } from "react-icons/fa"; // Import the FaCaretDown icon from react-icons
import { BsLightningFill } from "react-icons/bs";
import { RainbowButton } from "./Rainbow"; // Adjust the import path as necessary
import { MdOutlineAutoAwesome } from "react-icons/md";

function Navbar({ onLoginClick, handleSignOut }) {
  const [couseDropdownOpen, setCouseDropdownOpen] = useState(false);
  const [appsDropdownOpen, setAppsDropdownOpen] = useState(false);
  const [learnDropdownOpen, setLearnDropdownOpen] = useState(false);
  const { user, isPaid, idToken } = useContext(AuthContext);
  const appsDropdownRef = useRef(null); // Create a ref for the apps dropdown
  const learnDropdownRef = useRef(null); // Create a ref for the learn dropdown
  const CourseDropdownRef = useRef(null); // Create a ref for the Course dropdown

  const [courseDropdownOpen, setCourseDropdownOpen] = useState(false);
  const [userDropdownOpen, setUserDropdownOpen] = useState(false);
  const userDropdownRef = useRef(null); // Create a ref for the user dropdown

  useEffect(() => {
    // Event listener to handle clicks outside the dropdowns
    const handleOutsideClick = (event) => {
      if (
        appsDropdownRef.current &&
        !appsDropdownRef.current.contains(event.target)
      ) {
        setAppsDropdownOpen(false);
      }
      if (
        learnDropdownRef.current &&
        !learnDropdownRef.current.contains(event.target)
      ) {
        setLearnDropdownOpen(false);
      }
    };

    // Attach the event listener when the dropdowns are open
    if (appsDropdownOpen || learnDropdownOpen) {
      document.addEventListener("click", handleOutsideClick);
    }

    // Clean up the event listener when the component unmounts or the dropdowns are closed
    return () => {
      document.removeEventListener("click", handleOutsideClick);
    };
  }, [appsDropdownOpen, learnDropdownOpen]);
  // console.log("user", user);

  useEffect(() => {
    const handleOutsideClick = (event) => {
      if (
        CourseDropdownRef.current &&
        !CourseDropdownRef.current.contains(event.target)
      ) {
        setCourseDropdownOpen(false);
      }
    };
    if (courseDropdownOpen) {
      document.addEventListener("click", handleOutsideClick);
    }
    return () => {
      document.removeEventListener("click", handleOutsideClick);
    };
  }, [courseDropdownOpen]);

  useEffect(() => {
    const handleOutsideClick = (event) => {
      if (
        userDropdownRef.current &&
        !userDropdownRef.current.contains(event.target)
      ) {
        setUserDropdownOpen(false);
      }
    };
    if (userDropdownOpen) {
      document.addEventListener("click", handleOutsideClick);
    }
    return () => {
      document.removeEventListener("click", handleOutsideClick);
    };
  }, [userDropdownOpen]);

  const toggleCourseDropdown = () => {
    setCourseDropdownOpen(!courseDropdownOpen);
  };

  const toggleUserDropdown = () => {
    setUserDropdownOpen(!userDropdownOpen);
  };

  const CourseLinks = [
    { name: "School", path: "/school" },
    { name: "College", path: "/college" },
    { name: "References", path: "/references" },
    { name: "Languages", path: "/languages" },
    { name: "Subscribe", path: "/subscribe" },
    // Add other learn links here
  ];

  const appsLinks = [
    { name: "My Guides", path: "/myguide" },
    { name: "My Quizzes", path: "/myquiz" },
    { name: "Create New Guide", path: "/create" },
    { name: "Create New Course", path: "/course" },

    { name: "Subscribe", path: "/subscribe" },
    // Add other apps links here
  ];
  const toggleAppsDropdown = () => {
    setAppsDropdownOpen(!appsDropdownOpen);
  };

  const toggleLearnDropdown = () => {
    setLearnDropdownOpen(!learnDropdownOpen);
  };

  return (
    <nav id="navbar" className="bg-gray-100 dark:bg-gray-800 py-3 items-center">
      <div className="container px-3 md:px-3 flex items-center justify-between">
        <div className="flex flex-col items-center md:flex-row">
          <RainbowButton
            onClick={() => {
              /* Navigation logic if necessary */
            }}
          >
            <Link to="/" className="flex items-center">
              {/* Adjust icon and text size for smaller screens */}
              <MdOutlineAutoAwesome className="mr-2 text-xl md:text-2xl" />{" "}
              {/* Adjusted icon size */}
              <span className="text-3xl md:text-5xl font-semibold ">
                Kimavi
              </span>{" "}
              {/* Adjusted text size */}
            </Link>
          </RainbowButton>
        </div>

        <div className="flex items-center space-x-2 md:space-x-8">
          <div
            ref={learnDropdownRef}
            className="relative inline-flex items-center text-black cursor-pointer text-gray-800 dark:text-gray-200"
          >
            <a
              href="https://chromewebstore.google.com/detail/kimavi-create-how-to-vide/kcleenakjlelbmchbkfpgoldgglhdpne?hl=en&authuser=0&pli=1"
              target="_blank"
              rel="noopener noreferrer"
              className="bg-gray-400 hover:bg-blue-700 text-white py-1 px-2 rounded inline-flex items-center"
            >
              Ext
            </a>
          </div>

          <div
            ref={CourseDropdownRef}
            className="relative inline-flex items-center text-black cursor-pointer text-gray-800 dark:text-gray-200"
          >
            <span
              className="bg-gray-400 hover:bg-blue-700 text-white py-1 px-2 rounded inline-flex items-center"
              onClick={toggleCourseDropdown}
            >
              Courses
            </span>
            {courseDropdownOpen && (
              <ul className="absolute z-10 w-32 bg-black text-white rounded shadow-lg top-full mt-1">
                {CourseLinks.map((app, index) => (
                  <li key={index}>
                    <Link
                      to={app.path}
                      onClick={toggleCourseDropdown}
                      className="block px-4 py-2 text-sm hover:bg-blue-500 hover:text-white"
                    >
                      {app.name}
                    </Link>
                  </li>
                ))}
              </ul>
            )}
          </div>

          <div
            ref={appsDropdownRef}
            className="relative inline-flex items-center text-black cursor-pointer text-gray-800 dark:text-gray-200"
          >
            <span
              className="bg-gray-400 hover:bg-blue-700 text-white py-1 px-2 rounded inline-flex items-center"
              onClick={toggleAppsDropdown}
            >
              Create
            </span>
            {appsDropdownOpen && (
              <ul className="absolute z-10 w-32 bg-black text-white rounded shadow-lg top-full mt-1">
                {appsLinks.map((app, index) => (
                  <li key={index}>
                    <Link
                      to={app.path}
                      onClick={toggleAppsDropdown}
                      className="block px-4 py-2 text-sm hover:bg-blue-500 hover:text-white"
                    >
                      {app.name}
                    </Link>
                  </li>
                ))}
              </ul>
            )}
          </div>
          <div
            ref={userDropdownRef}
            className="relative inline-flex items-center text-black cursor-pointer text-gray-800 dark:text-gray-200"
          >
            <span
              className={`px-2 py-1 rounded hover:bg-blue-300 ${
                user ? "text-blue-700" : "text-gray-400"
              }`}
              onClick={toggleUserDropdown}
            >
              <FaUser size={24} />
            </span>
            {userDropdownOpen && (
              <ul className="absolute z-10 w-32 bg-black text-white rounded shadow-lg top-full mt-1">
                <li>
                  <Link
                    to="/myguide"
                    onClick={toggleUserDropdown}
                    className="block px-4 py-2 text-sm hover:bg-blue-500 hover:text-white"
                  >
                    My Guides
                  </Link>
                </li>
                <li>
                  <Link
                    to="/myquiz"
                    onClick={toggleUserDropdown}
                    className="block px-4 py-2 text-sm hover:bg-blue-500 hover:text-white"
                  >
                    My Quizzes
                  </Link>
                </li>
                <li>
                  <Link
                    to="https://chromewebstore.google.com/detail/kimavi-create-how-to-vide/kcleenakjlelbmchbkfpgoldgglhdpne?hl=en&authuser=0&pli=1"
                    onClick={toggleUserDropdown}
                    className="block px-4 py-2 text-sm hover:bg-blue-500 hover:text-white"
                  >
                    Install Extension
                  </Link>
                </li>

                {user ? (
                  <li>
                    <button
                      onClick={() => {
                        handleSignOut();
                        toggleUserDropdown();
                      }}
                      className="block w-full text-left px-4 py-2 text-sm hover:bg-blue-500 hover:text-white"
                    >
                      Logout
                    </button>
                  </li>
                ) : (
                  <li>
                    <button
                      onClick={() => {
                        onLoginClick();
                        toggleUserDropdown();
                      }}
                      className="block w-full text-left px-4 py-2 text-sm hover:bg-blue-500 hover:text-white"
                    >
                      Login
                    </button>
                  </li>
                )}
              </ul>
            )}
          </div>
        </div>
      </div>
    </nav>
  );
}

export default Navbar;
