import React, { useState, useEffect } from "react";
import { RainbowButtonHeader, RainbowButton1 } from "./Rainbow";
import { useNavigate } from "react-router-dom";
import { BsLightningFill } from "react-icons/bs";
import {
  MdOutlineAutoAwesome,
  MdOutlineVideoLibrary,
  MdGif,
  MdInsertDriveFile,
  MdViewCarousel,
} from "react-icons/md";
// Updated words and descriptions to match the new marketing message
const actionWords = ["Revolutionize", "Elevate"];
const actionDescriptions = [
  "Turn any process into How To Guides",
  "Assist Customers and Employees",
  "Build training docs and videos",
  "Better - Faster - Superior",
];

// Utilize Tailwind CSS for styling and animation
const tailwindBlink = "animate-ping";

function Hero() {
  const [actionIndex, setActionIndex] = useState(0);
  const navigate = useNavigate();
  const [contentTypeIndex, setContentTypeIndex] = useState(0); // State for cycling content types

  useEffect(() => {
    const wordChangeInterval = setInterval(() => {
      setActionIndex(
        (prevIndex) => (prevIndex + 1) % actionDescriptions.length
      );
    }, 3000); // Change the word every 4 seconds
    return () => clearInterval(wordChangeInterval);
  }, []);

  const currentAction = actionWords[actionIndex];
  const currentDescription = actionDescriptions[actionIndex];
  // Add an array for cycling words: Videos, GIF, Doc, Slide
  const contentTypes = ["Videos", "GIF", "Doc", "Slide"];
  const currentContentType = contentTypes[contentTypeIndex]; // Current content type (Videos, GIF, Doc, Slide)
  const contentTypeIcons = {
    Videos: { icon: MdOutlineVideoLibrary, color: "text-white" },
    GIF: { icon: MdGif, color: "text-white" },
    Doc: { icon: MdInsertDriveFile, color: "text-white" },
    Slide: { icon: MdViewCarousel, color: "text-white" },
  };

  const ContentTypeIcon = contentTypeIcons[currentContentType].icon;
  const iconColor = contentTypeIcons[currentContentType].color;

  useEffect(() => {
    const contentTypeInterval = setInterval(() => {
      setContentTypeIndex((prevIndex) => (prevIndex + 1) % contentTypes.length);
    }, 3000); // Change the content type every 2 seconds
    return () => clearInterval(contentTypeInterval);
  }, []);

  return (
    <>
      <div>
        <div className="container mx-auto text-center mt-12 mb-12">
          <h2 className="text-4xl font-bold  text-gray-400">
            Create
            <span className="text-[#00FFFF] m-1 p-1 rounded-lg">
              <RainbowButtonHeader onClick={() => navigate("/create")}>
                Step-by-Step
              </RainbowButtonHeader>
            </span>
            Guides
          </h2>
        </div>
        <div className="text-center">
          <h2 className="text-2xl font-bold text-gray-400 mb-4">
            Accelerate Knowledge Sharing
          </h2>
          <h3 className="text-xl font-bold text-gray-400 mt-4 "></h3>
        </div>
      </div>
      <header
        className="bg-fixed relative h-96"
        style={{
          backgroundSize: "cover",
        }}
        role="banner"
      >
        <div className="absolute m-4 p-8 inset-0 bg-gray-800  rounded-lg "></div>
        <div className="container mx-auto px-6 md:px-12 text-center py-8 md:py-32 relative">
          <h1 className="text-5xl font-bold mt-6 mb-8 leading-tight">
            <span className="text-[#00FFFF]">Create </span>
            <span className="text-[#00FFFF]">
              <span className={iconColor}>
                {/* Render the icon for the current content type */}
                <ContentTypeIcon className="inline-block" />
              </span>{" "}
              <span className="text-[#FFFFFF]">{currentContentType} </span>
              with{" "}
              <RainbowButton1 onClick={() => navigate("/create")}>
                <MdOutlineAutoAwesome className="mr-2" />
                Generative AI
              </RainbowButton1>
            </span>
          </h1>
          <p className="text-white text-xl max-w-4xl mx-auto">
            {currentDescription}
          </p>
        </div>
      </header>
      {/* Banner Image */}
      {/* <div
        className="h-96 bg-contain bg-center rounded-8xl bg-no-repeat relative p-4 m-1"
        style={{ backgroundImage: `url('/images/promo2.jpg')` }}
      ></div> */}

      <section className="bg-gray-50 text-gray-800 py-12 px-6 md:px-12">
        <div className="container mx-auto text-center">
          <h2 className="text-3xl font-bold mb-8 mt-8 text-gray-400">
            Create
            <span className="text-[#00FFFF] m-1 p-1 rounded-lg">
              <RainbowButton1 onClick={() => navigate("/create")}>
                How To Guides
              </RainbowButton1>
            </span>
            With Generative AI
          </h2>
          <div className="container mx-auto px-6 md:px-12 py-12">
            <div className="grid md:grid-cols-3 gap-4">
              {/* Card 1 */}

              <div className="bg-gray-800 rounded-lg shadow-xl p-6">
                <h3 className="text-lg font-bold mb-2 text-[#00FFFF]">
                  Unleash Generative AI
                </h3>
                <p className="text-md mb-4 text-white">
                  Magically create Digital Adoption content with generative AI,
                  unlocking new levels of creativity and efficiency
                </p>
              </div>

              {/* Card 2 */}

              <div className="bg-gray-800 rounded-lg shadow-xl p-6">
                <h3 className="text-lg font-bold mb-2 text-[#00FFFF]">
                  Collaborate and Share
                </h3>
                <p className="text-md mb-4 text-white">
                  Effortlessly share your innovative Digital Adoption Videos,
                  GIF, Docs, Slides with employees and customers
                </p>
              </div>

              {/* Card 3 */}

              <div className="bg-gray-800 rounded-lg shadow-xl p-6">
                <h3 className="text-lg font-bold mb-2 text-[#00FFFF]">
                  Supercharge Digital Adoption
                </h3>
                <p className="text-md text-white">
                  Supercharge Digital Adoption with Generative AI. Achieve
                  onboarding and training speeds up to 10X faster.
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="py-12 m-8 p-4 bg-gray-800 rounded-lg">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
          <div className="text-center">
            <h2 className="text-base text-white font-semibold tracking-wide ">
              Get Started with Generative How To
            </h2>
            <p className="mt-2 text-3xl leading-8 font-extrabold tracking-tight text-[#00FFFF] sm:text-4xl">
              Create In Just 3 Easy Steps
            </p>
          </div>

          <div className="mt-10">
            <ul className="md:grid md:grid-cols-3 md:gap-x-8 md:gap-y-10">
              {/* Step 1 */}
              <li>
                <div className="space-y-4">
                  <div className="text-lg leading-6 font-medium space-y-1">
                    <p className="text-white">Step 1</p>
                    <h3 className="text-gray-100">
                      Install the free extension
                    </h3>
                  </div>
                  <p className="text-gray-300">Sign up with Google login</p>
                </div>
              </li>

              {/* Step 2 */}
              <li>
                <div className="space-y-4">
                  <div className="text-lg leading-6 font-medium space-y-1">
                    <p className="text-white">Step 2</p>
                    <h3 className="text-gray-100">Create your first guide</h3>
                  </div>
                  <p className="text-gray-300">
                    Click through your process to capture steps
                  </p>
                </div>
              </li>

              {/* Step 3 */}
              <li>
                <div className="space-y-4">
                  <div className="text-lg leading-6 font-medium space-y-1">
                    <p className="text-white">Step 3</p>
                    <h3 className="text-gray-100">Share it with your team</h3>
                  </div>
                  <p className="text-gray-300">
                    Just share the link or send a GIF
                  </p>
                </div>
              </li>
            </ul>
          </div>
        </div>
      </section>
    </>
  );
}

export default Hero;
