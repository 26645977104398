import React, { useState, lazy, Suspense } from "react";
const RenderContent = lazy(() => import("../../RenderContent"));

const TrueFalseQuestion = ({
  question,
  showAnswer,
  correctAnswer,
  handleAnswerSelection,
}) => {
  // console.log("showAnswer", showAnswer);
  const handleOptionClick = (option) => {
    if (!showAnswer) {
      handleAnswerSelection(option);
    }
  };

  return (
    <div>
      <Suspense fallback={<div>Loading...</div>}>
        <h2 className="text-lg font-bold mb-2">
          <RenderContent content={question.text} />
        </h2>
      </Suspense>
      <div className="flex space-x-4">
        <button
          className={`bg-gray-200 text-gray-800 px-4 py-2 rounded-md flex-grow ${
            showAnswer ? "pointer-events-none" : ""
          }`}
          onClick={() => handleOptionClick(true)}
        >
          True
        </button>
        <button
          className={`bg-gray-200 text-gray-800 px-4 py-2 rounded-md flex-grow ${
            showAnswer ? "pointer-events-none" : ""
          }`}
          onClick={() => handleOptionClick(false)}
        >
          False
        </button>
      </div>
      {showAnswer && (
        <div className="mt-4">
          <p>
            Correct Answer:{" "}
            <span className="font-bold">
              {correctAnswer ? "True" : "False"}
            </span>
          </p>
        </div>
      )}
    </div>
  );
};

export default TrueFalseQuestion;
