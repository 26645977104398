import React, { useState, useEffect } from "react";
import {
  FaVideo,
  FaBolt,
  FaLightbulb,
  FaBullseye,
  FaArrowLeft,
  FaArrowRight,
} from "react-icons/fa";

const cards = [
  {
    icon: <FaBolt className="text-yellow-500 text-6xl mx-auto my-4" />,
    title: "Interactive Quiz Creation & Gameplay",
    description:
      "Experience the thrill of learning with Kimavi's engaging quiz platform. Perfect for schools and colleges.",
  },
  {
    icon: <FaLightbulb className="text-yellow-300 text-6xl mx-auto my-4" />,
    title: "AI-Powered 100 seconds Quizzes and Videos for Enhanced Learning",
    description:
      "Elevate your study sessions with Kimavi's advanced AI-powered quizzes. ",
  },
  {
    icon: <FaVideo className="text-blue-500 text-6xl mx-auto my-4" />,
    title: "Easily Create HowTo Guides with Videos",
    description:
      "Unlock the power of visual learning with Kimavi's HowTo Video feature. Our platform simplifies the process of creating and sharing instructional HowTo videos. ",
  },
  {
    icon: <FaBullseye className="text-red-500 text-6xl mx-auto my-4" />,
    title: "Comprehensive Student Tools for Goal Setting & Organization",
    description:
      "Our OKR tool empowers you to set and track  goals effectively. Manage your resources effortlessly with our Go Links feature, and explore your heritage with our unique Family Tree tool.",
  },
];

function CardSlider() {
  const [activeIndex, setActiveIndex] = useState(0);

  useEffect(() => {
    const interval = setInterval(() => {
      setActiveIndex((current) => (current + 1) % cards.length);
    }, 5000);
    return () => clearInterval(interval);
  }, []);

  const goToPrevious = () => {
    setActiveIndex((current) => (current - 1 + cards.length) % cards.length);
  };

  const goToNext = () => {
    setActiveIndex((current) => (current + 1) % cards.length);
  };

  return (
    <div className="min-h-screen bg-gray-100 flex items-center justify-center p-2">
      <div className="container mx-auto text-center">
        <div className="text-center py-10">
          <h1 className="text-5xl font-bold text-gray-800">
            Learn and Create Fast with Generative AI
          </h1>
          <h2 className="text-3xl font-semibold text-gray-700 mt-2">
            AI Tools for Learning & Creating
          </h2>
        </div>
        <div className="bg-gray-800 flex justify-center items-stretch p-6 m-6 rounded-lg">
          <FaArrowLeft
            className="text-white text-2xl cursor-pointer self-center"
            onClick={goToPrevious}
          />
          <div className="flex-grow flex justify-center">
            <div className="w-96 h-96 bg-white rounded-lg shadow-lg transform transition duration-500 hover:scale-105 hover:shadow-2xl flex flex-col justify-between p-5 text-center">
              {cards[activeIndex].icon}
              <h2 className="text-2xl font-bold text-gray-800">
                {cards[activeIndex].title}
              </h2>
              <p className="mt-2 text-gray-600">
                {cards[activeIndex].description}
              </p>
            </div>
          </div>
          <FaArrowRight
            className="text-white text-2xl cursor-pointer self-center"
            onClick={goToNext}
          />
        </div>
      </div>
    </div>
  );
}

export default CardSlider;
