import React, { useEffect, useState } from "react";
import { AiOutlineClockCircle, AiOutlineSetting } from "react-icons/ai";

const Timer = ({ initialTime, isTimerStarted, quizTime, endQuiz }) => {
  const [timer, setTimer] = useState(initialTime);

  useEffect(() => {
    let timeoutId;

    const startTimer = () => {
      setTimer((prevTimer) => prevTimer + 1);
      timeoutId = setTimeout(startTimer, 1000);
    };

    if (isTimerStarted && timer < quizTime) {
      timeoutId = setTimeout(startTimer, 1000);
    }

    // Check if the remaining time has reached 0 seconds
    if (isTimerStarted && timer === quizTime) {
      endQuiz();
    }

    return () => clearTimeout(timeoutId);
  }, [isTimerStarted, timer, quizTime]);

  return (
    <div className="text-center my-4">
      <div className="flex items-center justify-center space-x-2">
        <AiOutlineClockCircle className="text-xl" />
        <p className="font-bold">{timer} seconds</p>
      </div>
      <div className="flex items-center justify-center mt-4">
        <p className="font-bold">
          Remaining Time: {Math.max(0, quizTime - timer)} seconds
        </p>
      </div>
    </div>
  );
};

export default Timer;
