import React, { useEffect, useState, lazy, Suspense } from "react";
const RenderContent = lazy(() => import("../../RenderContent"));
const YesNoQuestion = ({
  question,
  showAnswer,
  correctAnswer,
  handleAnswerSelection,
}) => {
  const [selectedAnswer, setSelectedAnswer] = useState(null);

  const handleOptionClick = (option) => {
    if (!showAnswer) {
      setSelectedAnswer(option);
      handleAnswerSelection(option);
    }
  };

  return (
    <div>
      <Suspense fallback={<div>Loading...</div>}>
        <h2 className="text-lg font-bold mb-2">
          <RenderContent content={question.text} />
        </h2>
      </Suspense>
      <div className="flex space-x-4">
        <button
          className={`${
            (showAnswer && correctAnswer === true) ||
            (showAnswer && selectedAnswer === true)
              ? "bg-gray-200 text-gray-800"
              : "bg-gray-200 text-gray-800"
          } px-4 py-2 rounded-md flex-grow ${
            showAnswer ? "pointer-events-none" : ""
          }`}
          onClick={() => handleOptionClick(true)}
        >
          Yes
        </button>
        <button
          className={`${
            (showAnswer && correctAnswer === false) ||
            (showAnswer && selectedAnswer === false)
              ? "bg-gray-200 text-gray-800"
              : "bg-gray-200 text-gray-800"
          } px-4 py-2 rounded-md flex-grow ${
            showAnswer ? "pointer-events-none" : ""
          }`}
          onClick={() => handleOptionClick(false)}
        >
          No
        </button>
      </div>
      {showAnswer && (
        <div className="mt-4">
          <p>
            Correct Answer:{" "}
            <span className="font-bold">{correctAnswer ? "Yes" : "No"}</span>
          </p>
          {selectedAnswer !== null && (
            <p>
              You Selected:{" "}
              <span className="font-bold">{selectedAnswer ? "Yes" : "No"}</span>
            </p>
          )}
        </div>
      )}
    </div>
  );
};

export default YesNoQuestion;
